import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';

import { SearchParams } from '@azavista/advanced-search';
import { IErrorNotification, IEvent, ITokenPayload, IUser } from '@azavista/servicelib';
import { IAppInitializedResult, IAppThemeChangedData, IGlobalReportUpdatedData } from './shared.interfaces';

@Injectable({
    providedIn: 'root'
})
export class SubjectsService {
    private readonly appInitialized = new ReplaySubject<IAppInitializedResult>(1);
    private readonly currentUser = new ReplaySubject<IUser>(1);
    private readonly authenticationNotification = new ReplaySubject<ITokenPayload>(1);
    private readonly errorNotification = new ReplaySubject<IErrorNotification>(1);
    private readonly eventLoaded = new Subject<IEvent>();
    private readonly globalReportUpdated = new Subject<IGlobalReportUpdatedData>();
    private readonly participantsCustomWidgetFilter = new BehaviorSubject<SearchParams>(null);
    private readonly themeChanged = new ReplaySubject<IAppThemeChangedData>(1);
    private readonly themeChanged$ = this.themeChanged.asObservable();

    getAppInitialized(): ReplaySubject<IAppInitializedResult> {
        return this.appInitialized;
    }

    getCurrentUser(): ReplaySubject<IUser> {
        return this.currentUser;
    }

    getAuthenticationNotification(): ReplaySubject<ITokenPayload> {
        return this.authenticationNotification;
    }

    getErrorNotification(): ReplaySubject<IErrorNotification> {
        return this.errorNotification;
    }

    getEventLoadedSubject(): Subject<IEvent> {
        return this.eventLoaded;
    }

    getGlobalReportUpdated(): Subject<IGlobalReportUpdatedData> {
        return this.globalReportUpdated;
    }

    getParticipantsCustomWidgetFilter(): BehaviorSubject<SearchParams> {
        return this.participantsCustomWidgetFilter;
    }

    getThemeChangedSubject(): Subject<IAppThemeChangedData> {
        return this.themeChanged;
    }

    getThemeChangedObservable(): Observable<IAppThemeChangedData> {
        return this.themeChanged$;
    }
}
