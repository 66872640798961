import * as TRANSLATIONS from '../../assets/i18n/en-US.json';
import { TranslationKey } from './enums';

export type TranslationMapKey = ((keyof typeof TRANSLATIONS)) | TranslationKey | (string & {});
export type TranslationMap = { [Key in TranslationMapKey]: Key };

export const TRANSLATION_MAP: TranslationMap = Object.keys(TRANSLATIONS).reduce((record, key) => {
    (record as any)[key] = key;
    return record;
}, {} as TranslationMap);

export const USER_DEFAULT_USER_IMAGE = 'assets/img/user-profile-placeholder.png';

export const enum RouteQueryParamName {
    eventId = 'eventId',
}
