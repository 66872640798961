import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { IAutocompleteInputFieldSearchTextChangedEventArgs, IValueChangesWithObject } from '@azavista/components/shared';
import {
    IRelationInputFieldSearchTextChangedEventArgs,
} from '@azavista/components/grouped-fields';
import { ICreateNewEntityComponentData } from './create-new-entity.interfaces';


@Component({
    templateUrl: 'create-new-entity.component.html',
})
export class CreateNewEntityComponent {
    lastChanges: IValueChangesWithObject;

    constructor(
        public dialogRef: MatDialogRef<CreateNewEntityComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ICreateNewEntityComponentData
    ) {
    }

    onValuesChanged(data: IValueChangesWithObject): void {
        this.lastChanges = data;
        if (this.data.valuesChanged) {
            this.data.valuesChanged.next(data);
        }
    }

    onRelationInputSearchTextChanged(data: IRelationInputFieldSearchTextChangedEventArgs): void {
        if (this.data.relationInputSearchTextChangedSubject) {
            this.data.relationInputSearchTextChangedSubject.next(data);
        }
    }

    onAutocompleteSearchTextChanged(data: IAutocompleteInputFieldSearchTextChangedEventArgs): void {
        if (this.data.autocompleteInputSearchTextChangedSubject) {
            this.data.autocompleteInputSearchTextChangedSubject.next(data);
        }
    }

    onCancelled(): void {
        this.data.actionSubject.next(null);
    }

    onSaved(changes: IValueChangesWithObject): void {
        this.data.actionSubject.next(changes);
    }
}
