import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

import { AzavistaApiService } from '@azavista/servicelib';

@Injectable({
    providedIn: 'root'
})
export class FileDownloadService {

    constructor(private apiSvc: AzavistaApiService) {
    }

    async saveFile(fileUrl: string, fileName: string): Promise<void> {
        // TODO: Not all files are job files. In addition
        //       apiSvc.downloadJobFile internally prefixes the url with the base url https://.../3.0/
        //       and becomes unsuitable for downloading assets, which do not have /3.0/ in their URL
        const blob = await this.apiSvc.downloadJobFile(fileUrl);
        saveAs(blob, fileName);
    }

    async downloadAndSave(url: string, fileName: string): Promise<void> {
        const blob = await this.apiSvc.getFile(url);
        saveAs(blob, fileName);
    }
}
