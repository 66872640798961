import { Injectable } from '@angular/core';
import { UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AzavistaApiService } from '@azavista/servicelib';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {
    constructor(private apiSvc: AzavistaApiService, private router: Router) {
    }
    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!this.apiSvc.isAuthenticated()) {
            this.router.navigate(['/login'], { queryParams: { returnUrl: window.location.href } });
            return false;
        }
        return true;
    }
}
