<h1 class="aza-pa-dialog-title" mat-dialog-title translate>COPY_DESIGN_FROM_AZAVISTA_DESIGN_LIBRARY</h1>

<div mat-dialog-content class="dialog-container aza-pa-dialog-content">
    <div class="aza-pa-flex-column">
        <mat-form-field class="aza-pa-full-width">
            <mat-select [placeholder]="'Cluster'" [(value)]="chosenCluster"
                (selectionChange)="setAvailableItemsFromChosenCluster()" required>
                <mat-option *ngFor="let cluster of clusters" [value]="cluster">{{cluster.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="aza-pa-full-width">
            <mat-select [placeholder]="data.copyClusterType" [(value)]="chosenItem" required
                [disabled]="loading || !chosenCluster || !items?.length">
                <mat-option *ngFor="let item of items" [value]="item">{{item.name}}</mat-option>
            </mat-select>
            <mat-hint *ngIf="loading && chosenCluster">
                <span translate>LOADING_CLUSTER_ITEMS</span>...
            </mat-hint>
            <mat-hint *ngIf="!loading && chosenCluster && !items?.length">
                <span translate>NO_AVAILABLE_ITEMS_FOR_THIS_CLUSTER</span>
            </mat-hint>
        </mat-form-field>

        <mat-form-field class="aza-pa-full-width">
            <mat-select [placeholder]="'Teams'" [(value)]="chosenTeams" multiple required>
                <mat-option *ngFor="let team of teams" [value]="team">{{team.name}}</mat-option>
            </mat-select>
            <mat-hint><span translate>TEAMS_ASSOCIATED_WITH_ITEM_COPIED_FROM_CLUSTER</span></mat-hint>
        </mat-form-field>

        <ng-container *ngIf="isEventType">
            <mat-form-field class="aza-pa-full-width">
                <mat-select [placeholder]="'Default website theme'" [(value)]="chosenWebsiteTheme" required>
                    <mat-option *ngFor="let theme of websiteThemes" [value]="theme">{{theme.name}}</mat-option>
                </mat-select>
                <mat-hint *ngIf="loading && !chosenCluster">
                    <span translate>LOADING_CLUSTER_ITEMS</span>...
                </mat-hint>
            </mat-form-field>
            <mat-form-field class="aza-pa-full-width">
                <mat-select [placeholder]="'Default email theme'" [(value)]="chosenEmailTheme" required>
                    <mat-option *ngFor="let theme of emailThemes" [value]="theme">{{theme.name}}</mat-option>
                </mat-select>
                <mat-hint *ngIf="loading && !chosenCluster">
                    <span translate>LOADING_CLUSTER_ITEMS</span>...
                </mat-hint>
            </mat-form-field>
        </ng-container>
    </div>
</div>

<div mat-dialog-actions class="aza-pa-dialog-actions">
    <azavista-button (click)="onCancel()" class="aza-pa-dialog-action-cancel-button">
        <span translate>CANCEL</span>
    </azavista-button>
    <azavista-button (click)="onProceed()" color="primary" class="aza-pa-dialog-action-apply-button"
        [disabled]="!isFormReady()">
        <span translate>PROCEED</span>
    </azavista-button>
</div>
