import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

    transform(value: string | number | Date, format?: string, language = ''): string {
        return moment(value, '', language.toLocaleLowerCase()).format(format);
    }

}
