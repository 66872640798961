import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { IJobsStatusComponentData, IJobInfo, JobCategory, JobStatus, IJobCustomAction } from '@azavista/components/jobs-status';
import { AzavistaApiService, JobStatus as ServiceJobStatus } from '@azavista/servicelib';
import { SharedService } from './shared.service';
import { IJobGroupPollResult, IJobPollerPollSubjectItem, IPollGroup } from './shared.interfaces';

@Injectable({
    providedIn: 'root'
})
export class JobPollerService {

    private jobPollInterval = 1000;
    private groups: IPollGroup[] = [];
    private groupIdCounter = 0;
    // private jobPollResultSubject = new Subject<IJobGroupPollResult>();
    private pollSubjectItems: IJobPollerPollSubjectItem[] = [];

    constructor(private apiSvc: AzavistaApiService, private sharedSvc: SharedService) {
        this.pollJobs();
    }

    createGroup(componentData: IJobsStatusComponentData): string {
        if (!componentData.jobs) {
            componentData.jobs = [];
        }
        const group: IPollGroup = {
            id: this.getNextGroupId(), data: { componentData: componentData }
        };
        this.groups.push(group);
        return group.id;
    }

    addJob(groupId: string, jobId: string, options?: Omit<IJobInfo, 'job' | 'totals'>): Observable<IJobGroupPollResult> {
        if (!jobId) {
            return;
        }
        const group = this.getGroup(groupId);
        if (!group) {
            return null;
        }
        if (group.data.componentData.jobs.findIndex(x => x.job.id === jobId) >= 0) {
            // This job already exists
            return this.getPollSubject(groupId, jobId);
        }
        // Add dummy created job to an array so we can poll data for it
        const { completedCustomActions } = options ?? {};
        const emptyJobInfo = {...this.createEmptyJobInfo(), ...options ?? {}};
        emptyJobInfo.job.id = jobId;
        emptyJobInfo.completedCustomActions = completedCustomActions;
        group.data.componentData.jobs.push(emptyJobInfo);
        return this.getPollSubject(groupId, jobId).asObservable();
    }

    async cancelJob(jobId: string): Promise<void> {
        await this.apiSvc.cancelJob(jobId);
    }

    removeJob(jobId: string): boolean {
        const group = this.getJobGroup(jobId);
        if (!group) {
            return false;
        }
        const jobIndex = group.data.componentData.jobs.findIndex(x => x.job.id === jobId);
        if (jobIndex >= 0) {
            group.data.componentData.jobs.splice(jobIndex, 1);
            this.removePollSubjectItem(group.id, jobId);
        }
    }

    removeGroup(groupId: string): boolean {
        const groupIndex = this.groups.findIndex(x => x.id === groupId);
        if (groupIndex === -1) {
            return false;
        }
        this.groups.splice(groupIndex, 1);
        this.removePollSubjectItemsFromGroup(groupId);
        return true;
    }

    getPollSubjectItem(groupId: string, jobId: string): IJobPollerPollSubjectItem {
        const pollSubjectItem = this.pollSubjectItems.find(x => x.groupId === groupId && x.jobId === jobId);
        return pollSubjectItem;
    }

    private removePollSubjectItemsFromGroup(groupId: string): void {
        for (let i = this.pollSubjectItems.length - 1; i >= 0; i--) {
            if (this.pollSubjectItems[i].groupId === groupId) {
                this.pollSubjectItems.splice(i);
            }
        }
    }

    private removePollSubjectItem(groupId: string, jobId: string): void {
        const index = this.pollSubjectItems.findIndex(x => x.groupId === groupId && x.jobId === jobId);
        if (index >= 0) {
            this.pollSubjectItems.splice(index);
        }
    }

    private getPollSubject(groupId: string, jobId: string): Subject<IJobGroupPollResult> {
        let item = this.pollSubjectItems.find(x => x.groupId === groupId && x.jobId === jobId);
        if (!item) {
            item = { groupId: groupId, jobId: jobId, subject: new Subject<IJobGroupPollResult>(), jobInfo: null };
            this.pollSubjectItems.push(item);
        }
        return item.subject;
    }

    private hasJobs(): boolean {
        if (this.groups.length === 0) {
            return false;
        }
        for (const group of this.groups) {
            if (group.data.componentData.jobs.length > 0) {
                return true;
            }
        }
        return false;
    }

    private createEmptyJobInfo(): IJobInfo {
        const jobInfo: IJobInfo = {
            job: {
                label: '', startTime: '', status: JobStatus.pending, type: '',
                endTime: '', id: '', stages: [], category: JobCategory.update
            },
            totals: {
                completedCount: 0, completedPercentage: 0, errorsCount: 0, totalCount: 100
            }
        };
        return jobInfo;
    }

    private getJobGroup(jobId: string): IPollGroup {
        for (const group of this.groups) {
            const job = group.data.componentData.jobs.find(x => x.job.id === jobId);
            if (job) {
                return group;
            }
        }
        return null;
    }

    private pollJobs(): void {
        window.setTimeout(async () => {
            try {
                await this.loadJobsInfo();
            } catch (err) {
            }
            this.pollJobs();
        }, this.jobPollInterval);
    }

    private async loadJobsInfo(): Promise<void> {
        for (const group of this.groups) {
            const jobsForPolling = group.data.componentData.jobs.filter(x =>
                x.job.status === JobStatus.pending || x.job.status === JobStatus.running
            );
            for (const job of jobsForPolling) {
                let indexInMainArray = group.data.componentData.jobs.findIndex(x => x.job.id === job.job.id);
                if (indexInMainArray >= 0) {
                    // Still not removed - this can happen if during previous await the next job id was removed
                    const jobInfo = await this.apiSvc.getJob(job.job.id);
                    indexInMainArray = group.data.componentData.jobs.findIndex(x => x.job.id === job.job.id);
                    if (indexInMainArray >= 0) {
                        // Still not removed while waiting for response
                        // Set only these properties that come from the server keeping everythig else
                        // This will preserve all locally added properties like custom compete actions etc.
                        const componentJobInfo = group.data.componentData.jobs[indexInMainArray];
                        componentJobInfo.job = jobInfo.job as any;
                        componentJobInfo.totals = jobInfo.totals;
                    }
                    const isCompleted = jobInfo.job.status === ServiceJobStatus.completed;
                    const pollSubjectItem = this.pollSubjectItems.find(x => x.groupId === group.id && x.jobId === jobInfo.job.id);
                    if (pollSubjectItem) {
                        pollSubjectItem.jobInfo = jobInfo;
                        pollSubjectItem.subject.next({ group: group, jobInfo: jobInfo, completed: isCompleted });
                        if (isCompleted) {
                            pollSubjectItem.subject.complete();
                        }
                    }
                }
            }
        }
    }

    private getGroup(groupId: string): IPollGroup {
        return this.groups.find(x => x.id === groupId);
    }

    private getNextGroupId(): string {
        this.groupIdCounter++;
        return this.groupIdCounter.toString();
    }
}
