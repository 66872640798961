import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import {
    AzavistaApiService, ICopyCluster, IEvent, ITheme, ITeam, ThemeType, ClusterType, ICopyClustersRequest
} from '@azavista/servicelib';
import { ICopyFromClusterDialogData, ICopyFromClusterDialogResultData } from './copy-from-cluster.interfaces';
import { AclService } from '../acl.service';

@Component({
    templateUrl: 'copy-from-cluster.component.html'
})
export class CopyFromClusterComponent implements OnInit {
    isEventType: boolean;
    isThemeType: boolean;
    clusters: ICopyCluster[] = [];
    chosenCluster: ICopyCluster;
    items: IEvent[] | ITheme[];
    chosenItem: IEvent | ITheme;
    loading = true;
    teams: ITeam[];
    chosenTeams: ITeam[];
    emailThemes: ITheme[];
    chosenEmailTheme: ITheme;
    websiteThemes: ITheme[];
    chosenWebsiteTheme: ITheme;

    constructor(
        private apiSvc: AzavistaApiService,
        private aclSvc: AclService,
        public dialogRef: MatDialogRef<CopyFromClusterComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ICopyFromClusterDialogData
    ) {
        this.isEventType = this.data.copyClusterType === ClusterType.event;
        this.isThemeType = this.data.copyClusterType === ClusterType.theme;
    }

    async ngOnInit(): Promise<void> {
        this.loading = true;
        this.clusters = await this.getClusters();
        this.teams = await this.getUserTeams();
        this.emailThemes = await this.getThemes(ThemeType.email);
        this.chosenEmailTheme = this.emailThemes[0];
        this.websiteThemes = await this.getThemes(ThemeType.website);
        this.chosenWebsiteTheme = this.websiteThemes[0];
        this.loading = false;
    }

    async getUserTeams(): Promise<ITeam[]> {
        if (this.aclSvc.isAdmin()) {
            return await this.apiSvc.getAllTeams();
        }
        const currentUser = this.apiSvc.getCurrentUser();
        const req = {
            userId: currentUser.id,
            limit: 50,
            offset: 0
        };
        const response = await this.apiSvc.getUserTeams(req);
        return response.teams;
    }

    async getThemes(themeType: ThemeType): Promise<ITheme[]> {
        const themes = await this.apiSvc.getAllThemes(themeType);
        return themes;
    }

    async getClusters(): Promise<ICopyCluster[]> {
        const clusterType = this.data.copyClusterType;
        const request: ICopyClustersRequest = {
            limit: 50,
            offset: 0,
            type: clusterType
        };
        const response = await this.apiSvc.getCopyClusters(request);
        return response.copy_clusters;
    }

    async setAvailableItemsFromChosenCluster() {
        this.loading = true;
        if (this.isEventType) {
            await this.setClusterEventItems();
        }
        if (this.isThemeType) {
            await this.setClusterThemeItems();
        }
        this.loading = false;
    }

    async setClusterEventItems(): Promise<void> {
        const request = {
            copyClusterId: this.chosenCluster.id,
            limit: 50,
            offset: 0
        };
        const response = await this.apiSvc.getCopyClusterEventItems(request);
        this.items = response.events;
    }

    async setClusterThemeItems(): Promise<void> {
        const request = {
            copyClusterId: this.chosenCluster.id,
            type: this.data.themeItemType,
            limit: 50,
            offset: 0
        };
        const response = await this.apiSvc.getCopyClusterThemeItems(request);
        this.items = response.themes;
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onProceed(): void {
        const result = {
            copyCluster: this.chosenCluster,
            item: this.chosenItem,
            websiteTheme: this.chosenWebsiteTheme,
            emailTheme: this.chosenEmailTheme,
            teams: this.chosenTeams
        } as ICopyFromClusterDialogResultData;
        this.dialogRef.close(result);
    }

    isFormReady(): boolean {
        return !!(this.chosenCluster && this.chosenItem && this.chosenTeams);
    }
}
