import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// import { CanActivateUsers } from './route-guards/users.guard';
import { AuthGuard } from './route-guards/auth.guard';

const routes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(mod => mod.LoginModule)
    },
    {
        path: 'reset-password',
        loadChildren: () => import('./reset-password/reset-password.module').then(mod => mod.ResetPasswordModule)
    },
    {
        path: 'events', loadChildren: () => import('./events/events.module').then(mod => mod.EventsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'event',
        loadChildren: () => import('./event/event.module').then(mod => mod.EventModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'workflows',
        loadChildren: () => import('./events/list/events-list.module').then(mod => mod.EventsListModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'users', loadChildren: () => import('./users/users-parent.module').then(mod => mod.UsersParentModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard', loadChildren: () => import('./global-dashboard/global-dashboard.module').then(mod => mod.GlobalDashboardModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'crm', loadChildren: () => import('./crm/crm.module').then(mod => mod.CrmModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'documents', loadChildren: () => import('./documents/documents.module').then(mod => mod.DocumentsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'event-app', loadChildren: () => import('./event-app/event-app.module').then(mod => mod.EventAppModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'contacts-email-marketing', loadChildren: () => import('./contacts-email-marketing/contacts-email-marketing.module')
            .then(mod => mod.ContactsEmailMarketingModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'fields-manager', loadChildren: () => import('./fields-manager/fields-manager.module').then(mod => mod.FieldsManagerModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'field-editor', loadChildren: () => import('./shared/field-editor/field-editor.module').then(mod => mod.FieldEditorModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'tickets-and-products', loadChildren: () => import('./tickets-and-products/tickets-and-products.module')
            .then(mod => mod.TicketsAndProductsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'theme', loadChildren: () => import('./theme/theme.module').then(mod => mod.ThemeModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'my-account', loadChildren: () => import('./my-account/my-account.module').then(mod => mod.MyAccountModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'my-jobs', loadChildren: () => import('./my-jobs/my-jobs.module').then(mod => mod.MyJobsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'organization', loadChildren: () => import('./organization/organization.module').then(mod => mod.OrganizationModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'import', loadChildren: () => import('./import/import.module').then(mod => mod.ImportModule),
        canActivate: [AuthGuard]
    },
    // {
    //     path: 'integrations', loadChildren: () => import('./integrations/integrations.module').then(mod => mod.IntegrationsModule),
    //     canActivate: [AuthGuard]
    // },
    {
        path: 'global-processes', loadChildren: () => import('./global-processes/global-processes.module')
            .then(mod => mod.GlobalProcessesModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'rooms-management', loadChildren: () => import('./rooms-management/rooms-management.module')
            .then(mod => mod.RoomsManagementModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'website-builder',
        loadChildren: () => import('./website-builder/website-builder.module').then(mod => mod.WebsiteBuilderModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(mod => mod.ReportsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'project-management',
        loadChildren: () => import('./projects-management/projects-management.module').then(m => m.ProjectsManagementModule),
        canActivate: [AuthGuard]
    },
    {
        path: '**',
        redirectTo: '/dashboard',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
