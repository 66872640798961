import { Injectable } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';

import { AzavistaApiService, IUser } from '@azavista/servicelib';

@Injectable({
    providedIn: 'root'
})
export class SmartlookService {
    private apiLoadedCallbackName = '__azavistaSmartLookAPILoadedCallback';
    private smartlookAPILoadedSubject = new Subject<void>();
    private userAvailableSubject = new Subject<IUser>();

    constructor(private apiSvc: AzavistaApiService) { }

    init(): void {
        window[this.apiLoadedCallbackName] = () => {
            this.smartlookAPILoadedSubject.next();
        };
        combineLatest([this.userAvailableSubject, this.smartlookAPILoadedSubject]).subscribe({
            next: arr => {
                const user = arr[0];
                const smartlook = 'smartlook';
                if (window[smartlook] && user) {
                    window[smartlook]('identify', user.email);
                }
                if (window[smartlook]) {
                    window[smartlook]('record', { forms: true, emails: true, ips: true, numbers: true, api: true });
                }
            }
        });
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = this.getScript();
        document.head.appendChild(script);
        this.setCurrentUser();
    }

    setCurrentUser(): void {
        this.apiSvc.subscribeToCurrentUser({
            next: user => {
                if (user) {
                    this.userAvailableSubject.next(user);
                }
            }
        });
    }

    getScript(): string {
        return `
            window.smartlook||(function(d) {
                var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
                var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
                c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
                c.onload = function() { window['${this.apiLoadedCallbackName}'](); }
            })(document);
            smartlook('init', '99a815074d91af24ea1ceb74f598031415a16fdc', { region: 'eu' });
        `;
    }
}
