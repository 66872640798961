import { OnDestroy, Directive } from '@angular/core';
import { Subject, Observable, PartialObserver, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive()
export class DestroyableComponentDirective implements OnDestroy {
    private destroyed$ = new Subject<void>();
    private subscriptions: Subscription[] = [];

    safeSubscribe<T>(observable: Observable<T>, observer: PartialObserver<T>): void {
        observable.pipe(takeUntil(this.destroyed$)).subscribe(observer);
    }

    safeSubscription(subscription: Subscription): Subscription {
        this.subscriptions.push(subscription);
        return subscription;
    }

    ngOnDestroy(): void {
        for (const sub of this.subscriptions) {
            sub.unsubscribe();
        }
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
