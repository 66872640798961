import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class BeamerService {

    constructor() { }

    init() {
        (window as any).beamer_config = {
            product_id : 'DuocfcNV31337',
            force_button: false,
            selector: '#aza-beamer-button'
        };
        const scriptEl = document.createElement('script');
        scriptEl.type = 'text/javascript';
        scriptEl.src = 'https://app.getbeamer.com/js/beamer-embed.js';
        scriptEl.async = true;
        scriptEl.addEventListener('error', errEv => console.error('Beamer failed to initialize'));
        document.body.appendChild(scriptEl);
    }
}
