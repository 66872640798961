import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { INewOrImportDialogData, NewOrImportDialogAction } from './interfaces';
import { NewOrImportEntityType } from '../shared.interfaces';

@Component({
    templateUrl: 'new-or-import-dialog.component.html',
    styleUrls: ['new-or-import-dialog.component.css']
})
export class NewOrImportDialogComponent implements OnInit {
    isParticipant = false;
    isContact = false;
    isOrganization = false;

    constructor(
        private matDialog: MatDialogRef<NewOrImportDialogComponent, INewOrImportDialogData>,
        @Inject(MAT_DIALOG_DATA) private data: INewOrImportDialogData
    ) {
    }

    ngOnInit(): void {
        this.isParticipant = this.data.type === NewOrImportEntityType.eventParticipant;
        this.isContact = this.data.type === NewOrImportEntityType.contact;
        this.isOrganization = this.data.type === NewOrImportEntityType.organization;
    }

    onNewSelected(): void {
        this.data.action = NewOrImportDialogAction.addSingle;
        this.matDialog.close(this.data);
    }

    onUploadSelected(): void {
        this.data.action = NewOrImportDialogAction.uploadLocal;
        this.matDialog.close(this.data);
    }
}
