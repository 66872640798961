import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { IDownloadFilesDialogComponentData, IDownloadItem } from './interfaces';
import { FileDownloadService } from '../file-download.service';

@Component({
    templateUrl: './download-files-dialog.component.html',
})
export class DownloadFilesDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<DownloadFilesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IDownloadFilesDialogComponentData,
        private downloadSvc: FileDownloadService
    ) {
    }

    handleDownloadFile(item: IDownloadItem): void {
        this.downloadSvc.saveFile(item.url, item.fileName);
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }
}
