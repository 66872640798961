import { Pipe, PipeTransform } from '@angular/core';

/** Obtain the arguments' types of a function except the first argument */
type RestArgument<T> = T extends (first: any, ...args: infer Rest) => any
    ? Rest
    : [];


@Pipe({ name: 'apply' })
export class ApplyFunctionPipe implements PipeTransform {
  /**
   * @param callbackMethod method that is to be run using `ApplyFunctionPipe`.
   ** please pass an arrow function instead of class method
   */
  /**
     * @param callbackMethod method that is to be run using `ApplyFunctionPipe`.
     ** please pass an arrow function instead of class method
     */
     transform<Callback extends (value: any, ...extra: any[]) => any>(
        value: Parameters<Callback>[0],
        callbackMethod: Callback,
        ...extra: RestArgument<Callback>
    ): ReturnType<Callback> {
        return callbackMethod(value, ...extra);
    }
}
