import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AzavistaAutocompleteInputFieldComponent } from '@azavista/components/input-field';
import {
    FieldComponentType, IAutocompleteInputFieldValue, IAutocompleteSearchTextChangedData, IField, IIdWithLabel,
    IInputFieldOptions, IValueChangedEventArgs
} from '@azavista/components/shared';
import { AzavistaApiService, IEvent, ISearchEventsRequest } from '@azavista/servicelib';
import { debounceTime } from 'rxjs/operators';


@Component({
    selector: 'azavista3-quick-access-search',
    templateUrl: 'quick-access-search.component.html',
})
export class QuickAccessSearchComponent implements OnInit, AfterViewInit {
    @ViewChild('autocomplete') autocompleteField: AzavistaAutocompleteInputFieldComponent;
    @Output() selectedItem = new EventEmitter<IEvent>();

    eventField: IField;
    eventFieldOptions: IInputFieldOptions;
    eventFieldValue: IAutocompleteInputFieldValue;
    itemsBoxLabel: string;

    constructor(private apiSvc: AzavistaApiService) { }

    ngOnInit(): void {
        this.createEventFieldInfo();
    }

    ngAfterViewInit(): void {
        this.autocompleteField.valueChanged.pipe(debounceTime(1000)).subscribe(
            (data: IValueChangedEventArgs) => this.selectedItem.emit(data.value as IEvent)
        );
    }

    onFocus(): void {
        this.setLastEditedItems();
    }

    async onEventSearchTextChanged(data: IAutocompleteSearchTextChangedData): Promise<void> {
        const req: ISearchEventsRequest = {
            limit: 5, offset: 0, quickSearchText: data.text, sortFieldName: 'updated_at', sortDirection: 'desc'
        };
        const res = await this.apiSvc.searchEvents(req);
        this.eventFieldValue.items = res.events;
        this.eventFieldValue.filteredItems = res.events;
    }

    setLastEditedItems(): void {
        this.onEventSearchTextChanged({ text: '' } as IAutocompleteSearchTextChangedData);
    }

    createEventFieldInfo(): void {
        this.eventField = {
            editable: true,
        } as IField;
        this.eventField = {
            schema: { type: ['object'] },
            component: FieldComponentType.autocomplete
        } as IField;
        this.eventFieldOptions = {
            restoreLastSelectedValueWhenNoMatch: true,
            enableClearValue: true,
            useInternalFiltering: false
        };
        const allItems: IIdWithLabel[] = [];
        this.eventFieldValue = {
            displayProperty: 'name', items: allItems, selected: null
        };
        this.setLastEditedItems();
    }

}
