<div class="grouped-fields-container">
    <h3 mat-dialog-title class="aza-pa-dialog-title">{{data.title}}</h3>
    <div mat-dialog-content class="aza-pa-dialog-content">
        <azavista-selected-items-count [data]="data?.selectedItemsCountData"></azavista-selected-items-count>
        <ng-template *ngTemplateOutlet="data?.customTemplate"></ng-template>
        <azavista-grouped-fields [data]="data.groupedFields" [changeFieldValue]="data.changeFieldValue"
            [floatActions]="false"
            (valuesChanged)="onValuesChanged($event)" (cancelled)="onCancelled()" (saved)="onSaved($event)"
            (relationSearchTextChanged)="onRelationInputSearchTextChanged($event)"
            (autocompleteSearchTextChanged)="onAutocompleteSearchTextChanged($event)">
        </azavista-grouped-fields>
    </div>
</div>
