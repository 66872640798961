import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslateModule } from '@ngx-translate/core';

import { AzavistaButtonModule } from '@azavista/components/button';
import { AzavistaInputFieldModule } from '@azavista/components/input-field';
import { AzavistaExportedElementModule } from '@azavista/components/exported-element';
import { GroupedFieldsOrderComponent } from './grouped-fields-order/grouped-fields-order.component';
import { GroupedFieldsViewComponent } from './grouped-fields-view.component';

@NgModule({
    declarations: [GroupedFieldsViewComponent, GroupedFieldsOrderComponent],
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatIconModule,
        TranslateModule,
        AzavistaButtonModule,
        DragDropModule,
        AzavistaExportedElementModule,
        AzavistaInputFieldModule,
        MatCheckboxModule,
        MatDialogModule,
    ],
    exports: [GroupedFieldsViewComponent],
})
export class GroupedFieldsViewModule {}
