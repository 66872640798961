<mat-sidenav-container class="sidenav-container">
    <mat-sidenav [class.not-logged-in-mat-sidenav]="!isLoggedIn" #drawer [class.sidenav-expanded]="!sidenavCollapsed" [ngClass]="currentMenu === 1 ? 'general-dashboard' : 'event-dashboard'"
        [class.sidenav-collapsed]="sidenavCollapsed" fixedInViewport [attr.role]="'navigation'" mode="side" opened class="side-nav">
        <mat-toolbar class="client-logo-wrapper aza-pa-flex-row aza-pa-content-center" [ngClass]="currentMenu === 1 ? 'general-dashboard' : 'event-dashboard'">
            <img [src]="sidenavCollapsed ? 'assets/azavista_logo.png' : 'assets/img/azavista-color.png'"
                class="client-logo" [class.client-logo-collapsed]="sidenavCollapsed" />
        </mat-toolbar>
        <azavista-side-nav [data]="sideNavData" (collapsed)="onSidebarCollapsedChanged($event)"
            (menuClicked)="onMenuClicked($event)" class="side-nav-container"></azavista-side-nav>
    </mat-sidenav>
    <mat-sidenav-content [class.sidenav-content-expanded]="sidenavCollapsed" [style.backgroundColor]="'#f9f9f9'"
        [class.sidenav-content-collapsed]="!sidenavCollapsed" [class.signed-out-mat-sidenav-content]="!isLoggedIn"
        [class.signed-in-mat-sidenav-content]="isLoggedIn" class="sidenav-content">
        <div *ngIf="serverConnectionFailed" class="server-connection-lost aza-pa-padding aza-pa-margin">{{'YOU_ARE_CURRENTLY_OFFLINE' | translate}}
        </div>
        <mat-toolbar *ngIf="isLoggedIn" class="sidenav-top aza-pa-flex-row aza-pa-content-space-between"
            [class.sidenav-top--focussed]="isNavbarFocussed"
            [ngClass]="currentMenu === 1 ? 'general-dashboard' : 'event-dashboard'">
            <div class="sidenav-links aza-pa-flex-row">
                <a mat-raised-button [routerLink]="['/dashboard']" color="{{activeTopMenuPage === 'general-dashboard' ? 'primary' : 'secondary'}}"
                    [class.active-page]="activeTopMenuPage === 'general-dashboard'">
                    <span class="uppercased" translate>GENERAL_DASHBOARD</span>
                </a>
                <a mat-raised-button [class.active-page]="activeTopMenuPage === 'events'" [routerLink]="['/events']"
                    [disabled]="!hasEventsAccess()" color="{{activeTopMenuPage === 'events' ? 'primary' : 'secondary'}}">
                    <span class="uppercased" translate>EVENTS</span>
                </a>
                <button mat-button *ngIf="canGoToLegacy" (click)="navigateToAzavistaV2()" color="primary">
                    <span class="uppercased">Azavista 2.0</span>
                </button>
            </div>
            <azavista3-quick-access-search
                class="quick-access-search-widget aza-pa-medium-width"
                (click)="onQuickAccessSearchClick()"
                (clickOutside)="toggleNavbarFocused(false)"
                (selectedItem)="onQuickAccessItemSelected($event)">
            </azavista3-quick-access-search>
            <div class="sidenav-user-area aza-pa-flex-row aza-pa-no-gap">
                <button id="aza-beamer-button" mat-icon-button aza-beamer-button class="aza-beamer-button">
                    <mat-icon class="az-gray-text material-icons-outlined">notifications</mat-icon>
                </button>
                <button id="libraria-chatbot-button" mat-icon-button>
                    <mat-icon class="az-gray-text material-icons-outlined">help_outline</mat-icon>
                </button>
                <button id="user-menu-button" mat-button [matMenuTriggerFor]="userMenu">
                    <img
                        class="sidenav-user-avatar"
                        [src]="(currentUser | apply : getUserProfileImageUrl | async) ?? userDefaultImage"
                        alt="Profile avatar" />
                    <span class="sidenav-user-name">{{currentUser?.first_name}} {{currentUser?.last_name}}</span>
                    <mat-icon matSuffix>expand_more</mat-icon>
                </button>
            </div>
        </mat-toolbar>
        <!-- Add Content Here -->
        <div class="router-content aza-pa-padding">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>

<mat-menu #userMenu="matMenu">
    <div class="sidenav-user-area__user-menu">
        <div class="sidenav-user-area__user-menu__user-name">{{currentUser?.first_name}} {{currentUser?.last_name}}</div>
        <div class="sidenav-user-area__user-menu__user-email">{{currentUser?.email}}</div>
        <button class="sidenav-user-area__user-menu__settings" mat-menu-item (click)="navigateToMyAccount()">
            <mat-icon>settings</mat-icon>
            <span translate>MY_ACCOUNT</span>
        </button>
        <button class="sidenav-user-area__user-menu__jobs" mat-menu-item (click)="navigateToMyJobs()">
            <mat-icon>manage_history</mat-icon>
            <span translate>MY_JOBS</span>
        </button>
        <mat-divider></mat-divider>
        <div class="sidenav-user-area__user-menu sidenav-user-area__user-menu__knowledge-base" mat-menu-item (click)="userMenuKnowledgeBase.click()">
            <mat-icon>menu_book</mat-icon>
            <a #userMenuKnowledgeBase href="https://support.azavista.com/hc/en-us" target="_blank" translate>{{translations.KNOWLEDGE_BASE}}</a>
        </div>
        <div class="sidenav-user-area__user-menu sidenav-user-area__user-menu__tutorial-videos" mat-menu-item (click)="userMenututorialVideos.click()">
            <mat-icon>video_library</mat-icon>
            <a #userMenututorialVideos href="https://fast.wistia.com/embed/channel/gfo2g55qqk" target="_blank" translate>{{translations.TUTORIAL_VIDEOS}}</a>
        </div>
        <button class="sidenav-user-area__user-menu__checklist-onboarding" mat-menu-item (click)="launchUserGuidingChecklist(17951)">
            <mat-icon>checklist</mat-icon>
            <span translate>{{translations.CHECKLIST_ONBOARDING}}</span>
        </button>
        <button class="sidenav-user-area__user-menu__checklist-modules" mat-menu-item (click)="launchUserGuidingChecklist(17952)">
            <mat-icon>layers</mat-icon>
            <span translate>{{translations.CHECKLIST_MODULES}}</span>
        </button>
        <mat-divider></mat-divider>
        <button class="sidenav-user-area__user-menu__logout" mat-menu-item (click)="logout()">
            <mat-icon>exit_to_app</mat-icon>
            <span translate>LOG_OUT</span>
        </button>
    </div>
</mat-menu>
