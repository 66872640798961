<div class="confirmation-dialog-container">
    <h3 mat-dialog-title class="aza-pa-dialog-title">{{'FILE_DOWNLOAD_DIALOG_TITLE' | translate}}</h3>
    <div mat-dialog-content class="aza-pa-dialog-content">
        <div class="aza-pa-flex-column">
            <p class="aza-pa-primary-color">{{'FILE_DOWNLOAD_DIALOG_CONTENT' | translate}}</p>
            <div *ngFor="let item of data.items" class="aza-pa-primary-color">
                <azavista-flat-button (click)="handleDownloadFile(item)">
                    <mat-icon>download</mat-icon>
                    <span>{{item.label}}</span>
                </azavista-flat-button>
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="aza-pa-dialog-actions">
        <azavista-flat-button (click)="onCloseClick()" class="aza-pa-dialog-action-cancel-button" color="primary">
            <span>{{"CLOSE" | translate }}</span>
        </azavista-flat-button>
    </div>
</div>
