import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'integrationName'
})
export class IntegrationNamePipe implements PipeTransform {

    transform(value: string): string {
        if (value === 'ingress') {
            return 'white-label URL';
        }
        return value;
    }

}
