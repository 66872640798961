import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AppThemeService {
    private readonly themeCssLinkId = 'aza-pa-theme-link';
    async addAppThemeStylesheet(url: string): Promise<void> {
        let linkEl: HTMLLinkElement = document.head.querySelector(`#${this.themeCssLinkId}`);
        if (linkEl?.href === url) {
            // Already exists
            return Promise.resolve();
        }
        if (linkEl) {
            // Remove the existing link which have different href
            // If not removed, just changing the href does not work well with onload event handler
            linkEl.remove();
        }
        linkEl = document.createElement('link');
        linkEl.id = this.themeCssLinkId;
        linkEl.rel = 'stylesheet';
        linkEl.type = 'text/css';
        linkEl.href = url;
        document.head.appendChild(linkEl);
        return new Promise<void>((resolve, reject) => {
            linkEl.onload = () => {
                resolve();
            };
            linkEl.onerror = () => {
                reject();
            };
        });
    }
}
