import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslateModule } from '@ngx-translate/core';

import { AzavistaTableModule } from '@azavista/components/table';
import { AzavistaButtonModule } from '@azavista/components/button';
import { AzavistaGroupedFieldsModule } from '@azavista/components/grouped-fields';
import { AzavistaSearchItemsModule } from '@azavista/components/search-items';
import { AzavistaInputFieldModule } from '@azavista/components/input-field';
import { AzavistaSelectedItemsCountModule } from '@azavista/components/selected-items-count';
import { BackToListComponent } from './back-to-list/back-to-list.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { CreateNewEntityComponent } from './create-new-entity/create-new-entity.component';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { DownloadFilesDialogComponent } from './download-files-dialog/download-files-dialog.component';
import { ReorderingDialogComponent } from './reordering-dialog/reordering-dialog.component';
import { IconViewComponent } from './icon-view-component/icon-view.component';
import { NewOrImportDialogComponent } from './new-or-import-dialog/new-or-import-dialog.component';
import { CopyFromClusterComponent } from './copy-from-cluster/copy-from-cluster.component';
import { EditModeButtonsComponent } from './edit-mode-buttons/edit-mode-buttons.component';
import { IntegrationNamePipe } from './pipes/integration-name.pipe';
import { GroupedFieldsViewModule } from './grouped-fields-view/grouped-fields-view.module';
import { NewOrTemplateDialogComponent } from './new-or-template-dialog';
import { ReadEventDetailsComponent } from './read-event-details/read-event-details.component';
import { CreateNewDashboardPopupComponent } from './create-new-dashboard-popup/create-new-dashboard-popup.component';
import { TemplateDialogComponent } from './template-dialog/template-dialog.component';
import { GenericDialogLoaderComponent } from './generic-dialog-loader/generic-dialog-loader.component';
import { SelectEventDialogComponent } from './select-event-dialog/select-event-dialog.component';
import { QuickAccessSearchComponent } from './quick-access-search/quick-access-search.component';
import { RecipientMessagesComponent } from './recipient-messages/recipient-messages.component';
import { ActivitySettingsFieldsComponent } from './activity-settings-fields/activity-settings-fields.component';
import { SelectValuePopupComponent } from './select-value-popup/select-value-popup.component';
import { ApplyFunctionPipe } from './pipes/apply-function.pipe';
import { RememberSelectedTabIndexDirective } from './remember-selected-tab-index.directive';
import { PublicPageContainerComponent } from './public-page-container/public-page-container.component';
import { ClickOutsideDirective } from './click-outside.directive';

@NgModule({
    imports: [
        CommonModule, FormsModule, RouterModule, MatIconModule, TranslateModule, MatDialogModule, MatButtonModule,
        AzavistaGroupedFieldsModule, AzavistaSearchItemsModule, AzavistaButtonModule, DragDropModule, MatMenuModule,
        MatInputModule, MatRadioModule, MatFormFieldModule, MatCheckboxModule, MatPaginatorModule, MatSelectModule,
        AzavistaInputFieldModule, GroupedFieldsViewModule, MatSlideToggleModule, MatProgressSpinnerModule,
        AzavistaTableModule, AzavistaSelectedItemsCountModule, MatExpansionModule
    ],
    declarations: [
        BackToListComponent, CreateNewEntityComponent, ConfirmationDialogComponent, ApplyFunctionPipe, DateFormatPipe,
        DownloadFilesDialogComponent, ReorderingDialogComponent, IconViewComponent,
        NewOrImportDialogComponent, CopyFromClusterComponent, EditModeButtonsComponent, IntegrationNamePipe,
        NewOrTemplateDialogComponent, ReadEventDetailsComponent, CreateNewDashboardPopupComponent, TemplateDialogComponent,
        GenericDialogLoaderComponent, SelectEventDialogComponent, QuickAccessSearchComponent, RecipientMessagesComponent,
        ActivitySettingsFieldsComponent, SelectValuePopupComponent, PublicPageContainerComponent,

        // Directives
        RememberSelectedTabIndexDirective,
        ClickOutsideDirective,
    ],
    exports: [
        CommonModule, FormsModule, RouterModule, MatIconModule, TranslateModule,
        BackToListComponent, AzavistaButtonModule, ApplyFunctionPipe, DateFormatPipe, IconViewComponent,
        CopyFromClusterComponent, EditModeButtonsComponent, IntegrationNamePipe, DragDropModule,
        TemplateDialogComponent, GenericDialogLoaderComponent, SelectEventDialogComponent, QuickAccessSearchComponent,
        RecipientMessagesComponent, ActivitySettingsFieldsComponent, MatButtonModule, MatExpansionModule,
        MatFormFieldModule, MatInputModule, MatDialogModule, PublicPageContainerComponent,

        // Directives
        RememberSelectedTabIndexDirective,
        ClickOutsideDirective,
    ],
    providers: [DateFormatPipe, IntegrationNamePipe]
})
export class SharedModule {
}
