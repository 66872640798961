import { Injectable } from '@angular/core';
import { AzavistaApiService, ICustomer, IUser } from '@azavista/servicelib';
import { Observable, Subject } from 'rxjs';
import { bufferTime, filter, takeUntil, tap } from 'rxjs/operators';
import { IUsageAnalyticsEvent, SubjectsService } from './shared';

const BATCH_MAX_TIME = 1000;

@Injectable({
    providedIn: 'root'
})
export class UsageAnalyticsService {
    private events = new Subject<IUsageAnalyticsEvent>();
    private customer: ICustomer;
    private user: IUser;
    private userChanged = new Subject<void>();

    constructor(private apiSvc: AzavistaApiService, private subjectsSvc: SubjectsService) { }

    init(): void {
        this.subjectsSvc.getCurrentUser()
            .pipe(
                tap(user => {
                    if (this.user !== user) {
                        this.userChanged.next();
                    }
                })
            )
            .subscribe({
                next: async user => {
                    this.user = user;
                    if (user) {
                        this.customer = await this.apiSvc.getCustomer();
                    }
                }
            });
    }

    getEvents(): Observable<IUsageAnalyticsEvent[]> {
        return this.events.asObservable().pipe(
            takeUntil(this.userChanged),
            bufferTime(BATCH_MAX_TIME),
            filter(batchedEvents => !!batchedEvents.length)
        );
    }

    addEvent(event: IUsageAnalyticsEvent): void {
        if (!this.user) {
            return;
        }
        const ownedEvent: IUsageAnalyticsEvent = {
            ...event,
            user: this.user.email,
            customer: this.customer?.name
        };
        this.events.next(ownedEvent);
    }

}
