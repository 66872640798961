<div class="confirmation-dialog-container">
    <h3 mat-dialog-title class="aza-pa-dialog-title">{{data.title}}</h3>
    <div mat-dialog-content class="aza-pa-dialog-content">
        <azavista-selected-items-count [data]="data.selectedItemsData"></azavista-selected-items-count>
        <p *ngFor="let line of data.contentLines">
            {{line}}
        </p>
    </div>
    <div mat-dialog-actions class="aza-pa-dialog-actions">
        <azavista-flat-button *ngIf="!data.hideCancelButton" (click)="onCancelClick()" class="aza-pa-dialog-action-cancel-button"
            color="primary">
            <span *ngIf="data.cancelButtonText">{{data.cancelButtonText}}</span>
            <span *ngIf="!data.cancelButtonText">{{"CANCEL" | translate }}</span>
        </azavista-flat-button>
        <azavista-button (click)="onOkClick()" color="primary" class="aza-pa-dialog-action-apply-button">
            <span *ngIf="data.okButtonText">{{data.okButtonText}}</span>
            <span *ngIf="!data.okButtonText">{{"OK" | translate }}</span>
        </azavista-button>
    </div>
</div>
