<h1 *ngIf="isParticipant" mat-dialog-title class="aza-pa-dialog-title" translate>ADD_NEW_PARTICIPANTS</h1>
<h1 *ngIf="isContact" mat-dialog-title class="aza-pa-dialog-title" translate>ADD_NEW_CONTACTS</h1>

<div mat-dialog-content class="aza-pa-dialog-content">
    <div class="options aza-pa-flex-row">
        <div (click)="onNewSelected()" class="option-box aza-pa-flex-column aza-pa-padding mat-elevation-z2 cursor-hand">
            <div class="icon-container">
                <mat-icon class="aza-pa-icon-big" color="primary">person_add</mat-icon>
            </div>
            <p *ngIf="isParticipant" class="option-text" translate>ADD_SINGLE_PARTICIPANT</p>
            <p *ngIf="isContact" class="option-text" translate>ADD_SINGLE_CONTACT</p>
            <p *ngIf="isOrganization" class="option-text" translate>ADD_SINGLE_ORGANIZATION</p>
        </div>
        <div (click)="onUploadSelected()" class="option-box aza-pa-flex-column aza-pa-padding mat-elevation-z2 cursor-hand">
            <div class="icon-container">
                <mat-icon class="aza-pa-icon-big" color="primary">create_new_folder</mat-icon>
            </div>
            <p class="option-text" translate>UPLOAD_FILE_FROM_DESKTOP</p>
        </div>

        <!-- TEMPORARILY REMOVED BECAUSE FUNCTIONALITY IS NOT READY -->

        <!--    <div class="option-box mat-elevation-z2 deactivated">-->
        <!--        <div class="icon-container">-->
        <!--            <mat-icon class="az-icon-big az-gray-text mat-icon-no-color">cloud_upload</mat-icon>-->
        <!--        </div>-->
        <!--        <p class="option-text az-gray-text" translate>UPLOAD_FROM_CLOUD</p>-->
        <!--    </div>-->
    </div>
</div>
